@import '../../index';

.contact {
    display: flex;
    flex-wrap: wrap;

    h1 {
        @include fontTitle;
        width: 100%;
    }

    &__options {
        display: flex;
        flex-direction: column;

        span {
            display: flex;
            align-items: center; 
            margin-bottom: 40px;
            a, p {
                @include fontText;
                text-decoration: none;
                display: flex;
                align-items: center; 
    
                img {
                    width: 48px;
                    margin-right: 30px;
                }
            }
        }
    }

    &__form {
        display: flex;
        flex-wrap: wrap;

        background-color: $lightgrey-color;
        padding: 30px;
        border-radius: 10px;

        h2 {
            @include fontTitle;
            font-size: $font-medium-large-mob !important;
        }

        div {
            margin-bottom: 10px;
        }

        div:last-of-type {
            display: flex;
            justify-content: flex-end;
        }

        .confirmation, .error {
            @include fontText;
            color: $blue-color;
            margin: auto;
        }

        .error {
            color: $orange-color;
        }
    }

    &__map {
        margin-top: 50px; 
        height: 600px;
    }
}

.contact--md {

}

.contact--mob {
    .contact__map {
        margin-bottom: 50px; 
        height: 300px;
    }
}