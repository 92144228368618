@import '../../index';

.input-select {
    @include inputStyle($input-height);

    &-wrapper {
        position: relative;
    }

    &__options::-webkit-scrollbar {
        display: none;
    }

    &__options {
        position: absolute;
        top: 40px;
        width: 100%;
        min-height: 150px;
        max-height: 150px;
        overflow-y: scroll;
        border: 1px solid $grey-color;
        border-radius: 10px; 
        list-style: none;
        padding: 0;
        background-color: white;
        z-index: 99;
        -ms-overflow-style: none;
        scrollbar-width: none;

        p {
          padding: 10px 20px;
          font-family: $secundary-font;
          font-size: $font-regular;
          margin: 0;
        }
        
        li {
          padding: 10px 20px;
          border-bottom: 1px solid $lightgrey-color;
          font-family: $secundary-font;
          font-size: $font-regular;
          transition: ease-in-out 0.5s;
          cursor: pointer;
        }

        li:hover {
          background-color: $lightgrey-color;
        }
    }

    &__input {
        /* for Firefox */
        -moz-appearance: none;
        /* for Chrome */
        -webkit-appearance: none;

        background-image: url('../../assets/icons/chevron-down-solid.svg');
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: 95%;
        font-size: $font-regular;
    }
}

.input-select::-ms-expand {
    display: none;
}

.input-select__input:hover {
    cursor: pointer;
}

/** Media queries **/
@media (max-width: 991.98px) {
    .input-select {
        &__input {
            font-size: $font-small !important;
            background-size: 10px;
        }

        &__label {
            font-size: $font-small !important;
        }
    }
}