@import '../../../index';

.head-search-engine {
    z-index: 10;
    background-color: $bg-color;
    border-radius: calculateRem(10px);
    box-shadow: 0 3px 12px rgba($color: #000000, $alpha: 0.2);
    padding: calculateRem(40px) calculateRem(40px) calculateRem(40px) calculateRem(40px);
    width: 100%;
    position: relative;
    top: calculateRem(-130px);

    &__input {
        margin-bottom: 20px;

        &--location {
            display: flex;
    
            .input-text {
                min-width: 50%;
                input {
                    border-radius: 10px 0 0 10px;
                    width: 100%;
                }
            }
    
            .input-select {
                min-width: 50%;
                select {
                    border-radius: 0 10px 10px 0;
                    border-left: none;
                }
            }
        }
    }
}

/** Media queries **/
@media (max-width: 991.98px) {
    .head-search-engine {
        top: calculateRem(-90px);

        &__input {
            &--location {
                margin-bottom: calculateRem(20px);
            }
        }
    }
}