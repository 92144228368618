@import '../../index';

.input-date {
    @include inputStyle($input-height);

    input::-webkit-datetime-edit-year-field:not([aria-valuenow]),
    input::-webkit-datetime-edit-month-field:not([aria-valuenow]),
    input::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
        color: transparent;
        font-family: $primary-font;
    }

    &__input[type="date"] {
        font-family: $primary-font;
    }

    &__input::-webkit-calendar-picker-indicator {
        opacity:0;   
        -webkit-appearance: none;    
    }

    &__icon {
        position: absolute;
        right: 30px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        width: 20px;
    }
}

// Media queries
@media (max-width: 991.98px) {
    .input-date {
        &__input {
            font-size: $font-small !important;
        }

        &__label {
            font-size: $font-small !important;
        }
    }
}