@import '../../../index';

.contact {
    margin-bottom: 150px;
    padding-bottom: 150px;
    position: relative;
    overflow: hidden;

    &__info {
        &--title {
            @include fontTitle;
        }

        &--button {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding-top: 50px;

            &--mail {
                background-color: $blue-color;
                padding: 10px 20px;
                border-radius: 10px;
                font-size: $font-regular;
                color: #fff;
                text-decoration: none;
                font-family: $secundary-font;
                font-weight: 500;
                margin-right: 10px;
            }

            &--action {
                font-size: $font-regular;
                color: #000000;
                font-family: $secundary-font;
            }
        }

        &--reaches {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            span {
                padding-right: 40px;
                h5 {
                    font-weight: 300;
                    font-family: $primary-font;
                    font-size: $font-medium;
                    color: $darkgrey-color;
                }

                a, p {
                    img {
                        width: 24px;
                        margin-right: 10px;
                    }
                    display: flex;
                    align-items: center;
                    color: #000000;
                    text-decoration: none;
                    font-family: $secundary-font;
                    font-size: $font-regular;
                }
            }
        }
    }

    &__map {
        height: 100%;
    }

    &__map.right-sided-map {
        width: 40%;
        position: absolute;
        right: 0;
    }

    &__map.responsive-map {
        margin-top: 50px;
        width: 100%;
        height: 250px;
        left: 0;
    }
}

/** Media queries **/
@media (max-width: 991.98px) {
    .contact {
        padding-bottom: 0px;
    }
    .contact__info {
        &--title {
            font-size: calculateRem(28px);
        }
    }
}
