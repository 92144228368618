@import '../../index';

.slide-button:hover {
    .slide-button__icon {
        left: 100%;
    }

    p {
        padding: 0 40px;
        text-decoration: none;
    }
}

.slide-button {
    display: inline-flex;
    align-items: center;
    background-color: #42BBFF;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    height: 50px;
    cursor: pointer;

    &__icon {
        position: absolute;
        left: 0;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $blue-color;
        overflow: hidden;
        transition: ease-in-out 0.5s;

        img {
            height: 40%;
        }
    }

    p {
        margin: 0;
        padding: 0 30px 0 70px;
        transition: ease-in-out 0.5s;
        color: white;
        font-family: $primary-font;
        font-weight: 700;
        font-size: $font-regular;
        text-decoration: none !important;
    }
}