@import '../../index';

.input-file {

    &__dropzone {
        width: 100%;

        border-radius: 10px;;
        border: 2px dashed $blue-color;

        padding: 25px;

        display: flex;
        flex-direction: column;
        align-items: center;

        @include fontText;
        color: $darkgrey-color;

        .input-file-dropzone__button {
            @include noselect;
            color: $white-color;
            font-size: 20px;
            width: 150px;
            background-color: $blue-color;
            border-radius: 10px;

            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            p{
                margin-right: 15px;
                font-size: 15px;
            }
            img {
                width: 15px;
            }
        }
    }

    &__uploaded {
        display: flex;
        flex-wrap: wrap;
        @include fontText;
        font-size: 13px;
        color: $blue-color;
        min-height: 39px;
        padding: 5px 0;
        .file-tag {
            display: flex;
            align-items: center;
            height: 25px;
            margin: 2px 8px;
            padding: 0px 10px;
            cursor: pointer;

            border-radius: 8px;;
            background-color: $lightblue-color;

            img {
                width: 15px;
                margin-left: 10px;

                text-align: center;
                color: $white-color;
                cursor:pointer;
            }
        }
    }
}