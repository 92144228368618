@import '../../../index';

.why-subjects {
  margin: 120px 0 120px 0;

  &__title {
    @include fontTitle;
  }

  &__subject {
    background: #FFFFFF;
    box-shadow: 0px 3px 22px rgba(64, 64, 64, 0.1);  
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    min-height: 230px;

    &--icon {
      width: 60%;
      height: 60%;
    }

    &--title {
      margin: 0;
      font-family: $primary-font;
      font-size: $font-regular;
      text-align: center;
      margin-top: 20px;
    }
  }
}
