@import '../../index';

.loading {
  position: absolute;
  z-index: 1000;
  background: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &-wrapper {
    img {
      width: 180px;
      height: 180px;
    }

    h5 {
      text-align: center;
      font-size: $font-regular;
      font-family: $primary-font;
    }
  }
}