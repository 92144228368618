@import '../../index';

.vacancy-page-container {
    margin-top: 60px; 
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .vacancy-detail {
        background-color: transparent;
        border-radius: 0 10px 10px 0;
        padding: 0;
        padding-right: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;   
    }
    
    .vacancy-cta {
        background-color:$lightgrey-color;
        border-radius: 10px;
        padding: 20px 50px;
    
        &__container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 25px;
    
            h3 {
                font-family: $secundary-font;
                font-size: calculateRem($font-medium);
                font-weight: 500;
                color: $mediumgrey-color;
                margin: 0 0 15px;
            }
    
            input {
                height: 40px;
                width: 200px;
            }

            hr {
                margin: 10px 0;
            }
            div {
                padding: 30px 0;
            }

            &--share {
                margin-top: 15px;
            }
    
            .vacancy-cta__contact {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                h3 {
                    min-width: 100%;
                    margin-bottom: 30px;
                }

                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 50%;
                    margin-right: 10px;
                }
    
                p {
                    @include fontTitle;
                    font-size: $font-medium;
                    margin: 0 0 10px 0;
                }
    
                ul {
                    @include fontText;
                    list-style: none; /* Remove default bullets */
                    padding:  0 15px;
                    margin: 0;
                }
    
                ul li {
                    display: flex;
                    align-items: center;

                    a {
                        @include fontText;
                        text-decoration: none;
                    }

                    span {
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        background-color: $orange-color;
                        border-radius: 50%;
                        margin-right: 10px
                    }
                }
            }
        }
    }
}



.vacancy-page-container--md {
    // flex-direction: column;
}

.vacancy-page-container--mob {
    // flex-direction: column;
    align-items: initial;
    .vacancy-detail {
        padding: 0;
    }
    .vacancy-cta {
        padding: 30px;
        margin-top: 30px;
        &__container {
            hr {
                margin: 40px 0;
            }
        }
    }
}

@media print {
    .searchbar, .vacancy-cta__cta, .footer {
        display: none;
    }

    p, li, h3,span {
        font-size: 8px !important;
        a {
            font-size: 8px !important;
        }
    }

    h1 {
        font-size: 14px !important;
    }

    hr {
        display: none;
    }

    .vacancy-cta__contact img {
        width: 40px!important;
        height: 40px!important;
    }
}