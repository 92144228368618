@import '../../index';

.blog {
  &__back {
    color: $orange-color;
    font-size: $font-regular;
    font-family: $primary-font;
  }

  &__img-wrapper {
    border-radius: 10px;
    overflow: hidden;
    max-height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__img-full {
    margin-top: 30px;
    margin-bottom: 40px;
    width: 100%;
    max-height: 300px;
    object-fit: cover;
  }

  &__title {
    @include fontTitle;
  }

  &__content {
    @include fontText;
    margin-bottom: 100px;
  }
}