@import '../../../index';

.blogs-section.bg-tertiary {
  padding: 50px 0;
  margin-bottom: 0;
}

.blogs-section {
    position: relative;
    width: 100%;
    margin-bottom: 150px;

    &__title {
      @include fontTitle;
    }

    &__more {
      margin-top: 30px;
      a {
        background-color: $blue-color;
        padding: 10px 20px;
        border-radius: 5px;
        color: white;
        font-family: $primary-font;
        font-size: $font-regular;
        font-weight: 500;
        text-decoration: none;
      }      
    }

    &__more:hover {
      opacity: 0.8;
    }

    &__overview {
      &--item {
        margin-bottom: 20px;
        height: 520px;
        background: #FFFFFF;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.07);
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        &__img {
          max-height: 200px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.7s;
          }
        }

        &__wrapper {
          padding: 20px 30px 50px 30px;
        }

        &__title {
          text-decoration: none;
          color: $orange-color;

          h5 {
            color: $orange-color;
            font-family: $primary-font;
            font-size: $font-medium;
          }
        }

        &__title:hover {
          text-decoration: underline;
        }

        p {
          @include fontText;
        }

        &__more {
          color: $orange-color;
          font-family: $primary-font;
          font-size: $font-regular;
          position: absolute;
          right: 20px;
          bottom: 20px;
        }
      }

      &--item:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
}