@import "../../index";

.popup {
    background-color: rgba($color: #000000, $alpha: 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 1005;
    left: 0;
    top: 0;
    transition: ease-in-out 0.5s;
    animation: 1s fadeIn alternate;
    -o-animation: 1s fadeIn alternate;
    -moz-animation: 1s fadeIn alternate;
    -webkit-animation: 1s fadeIn alternate;

    .invisible-clickable {
        position: absolute;
        height: 100vh;
        width: 100%;
        left: 0;
        top: 0;
    }

    &__profile {
        background-color: white;
        padding: 30px;
        border-radius: 10px;

        h2 {
            font-family: $secundary-font;
            text-align: center;
            font-size: $font-regular;
            font-weight: 300;
        }

        &--content {
            &--button {
                margin-top: 20px;
                display: flex;
                justify-content: center;

                span.delete-button {
                    margin: 5px;
                    background: rgba($color: #dc3545, $alpha: 1.0);
                    color: white;
                    font-family: $primary-font;
                    font-weight: bold;
                    font-size: $font-regular;
                    padding: 10px 20px;
                    cursor: pointer;
                    border-radius: 10px;
                }

                span.back-button {
                    margin: 5px;
                    background: rgba($color: $grey-color, $alpha: 1.0);
                    color: black;
                    font-family: $primary-font;
                    font-weight: bold;
                    font-size: $font-regular;
                    padding: 10px 20px;
                    cursor: pointer;
                    border-radius: 10px;
                }
            }
        }
    }

    &__auth {
        width: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        height: 500px;
        position: relative;
        z-index: 1007;

        &--form {
            padding: 60px 50px;
            width: 50%;

            .succes-holder {
                animation: 2s fadeIn alternate;
                -o-animation: 2s fadeIn alternate;
                -moz-animation: 2s fadeIn alternate;
                -webkit-animation: 2s fadeIn alternate;

                h1 {
                    text-align: center;
                    margin: 0;
                }

                p {
                    text-align: center;
                    font-family: $secundary-font;
                    font-size: $font-regular;
                }

                &__icon {
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;

                    img {
                        width: 70px;
                    }
                }
            }

            .form-container {
                display: block;
                position: relative;
                transition: ease-in-out 0.5s;
            }

            .form-container.next-form {
                opacity: 0 !important;
            }

            .form-container.hidden-form {
                display: none !important;
                opacity: 0;
            }

            .form-container.show-hidden-form {
                opacity: 1;
            }

            &__button {
                margin-top: 50px;
                display: flex;
                justify-content: flex-end;
                width: 100%;

                .slide-button-container {
                    p {
                        font-family: $secundary-font;
                        cursor: pointer;
                        font-size: $font-regular;
                        text-align: center;
                        text-decoration: underline;
                    }

                    p:hover {
                        opacity: 0.5;
                    }
                }
            }

            h1 {
                font-size: $font-medium-large;
                font-family: $primary-font;
                font-weight: 500;
                margin: 0;
                margin-left: 20px;
            }

            h1.only-title {
                margin-bottom: 50px;
            }
            
            &--text {
                font-family: $secundary-font;
                font-size: $font-regular;
                margin-bottom: 40px;
                margin-left: 20px;

                strong {
                    font-weight: 700;
                    color: $darkgrey-color;
                }
            }

            .input-text:nth-of-type(1) {
                margin-bottom: 20px;
            }

            .input-text:nth-of-type(2) {
                margin-bottom: 10px;
            }

            span {
                font-family: $secundary-font;
                font-weight: 400;
                font-size: $font-regular;
                text-decoration: underline;
                cursor: pointer;
                margin-left: 20px;
            }

            span:hover {
                opacity: 0.5;
            }
        }

        &--animate {
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            animation: 2s fadeIn alternate;
            -o-animation: 2s fadeIn alternate;
            -moz-animation: 2s fadeIn alternate;
            -webkit-animation: 2s fadeIn alternate;

            &--logo {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
                
                img {
                    width: 300px;
                }
            }

            &--change {
                position: absolute;
                right: 20px;
                bottom: 20px;
                color: white;
                font-family: $secundary-font;
                font-size: $font-regular;
                font-weight: 200;

                strong {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            span {
                padding: 0 120px;
                h5, p {
                    margin: 0;
                    padding: 0;
                }

                h5 {
                    color: white;
                    font-family: $primary-font;
                    font-size: $font-regular;
                    text-align: center;
                }

                p {
                    color: white;
                    font-family: $secundary-font;
                    font-weight: 300;
                    text-align: center;
                    margin-top: 10px;
                    font-size: $font-regular;
                }
            }
        }

        &--animate.login {
            background: linear-gradient(180deg, #0095D5 0%, #18BAFF 100%);
        }

        &--animate.register {
            background: linear-gradient(180deg, #FF9250 0%, #FFA873 100%);;
        }
    }
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@-moz-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@-o-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@-webkit-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}